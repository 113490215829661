import { CellType, CellTypeToDisplayName } from "@hex/common";

import { CellContentsMP } from "../redux/slices/hexVersionMPSlice";

export const CellTypeNameToDisplay: Record<
  CellContentsMP["__typename"],
  string
> = {
  Parameter: CellTypeToDisplayName[CellType.INPUT],
  MarkdownCell: CellTypeToDisplayName[CellType.MARKDOWN],
  CodeCell: CellTypeToDisplayName[CellType.CODE],
  SqlCell: CellTypeToDisplayName[CellType.SQL],
  VegaChartCell: CellTypeToDisplayName[CellType.VEGA_CHART],
  DisplayTableCell: CellTypeToDisplayName[CellType.DISPLAY_TABLE],
  MetricCell: CellTypeToDisplayName[CellType.METRIC],
  TextCell: CellTypeToDisplayName[CellType.TEXT],
  MapCell: CellTypeToDisplayName[CellType.MAP],
  WritebackCell: CellTypeToDisplayName[CellType.WRITEBACK],
  DbtMetricCell: CellTypeToDisplayName[CellType.DBT_METRIC],
  PivotCell: CellTypeToDisplayName[CellType.PIVOT],
  FilterCell: CellTypeToDisplayName[CellType.FILTER],
  ComponentImportCell: CellTypeToDisplayName[CellType.COMPONENT_IMPORT],
  ChartCell: CellTypeToDisplayName[CellType.CHART],
  BlockCell: CellTypeToDisplayName[CellType.BLOCK],
  ExploreCell: CellTypeToDisplayName[CellType.EXPLORE],
};

export const CellTypeToTypeName: Record<
  CellType,
  CellContentsMP["__typename"] | undefined
> = {
  [CellType.INPUT]: "Parameter",
  [CellType.MARKDOWN]: "MarkdownCell",
  [CellType.CODE]: "CodeCell",
  [CellType.SQL]: "SqlCell",
  [CellType.VEGA_CHART]: "VegaChartCell",
  [CellType.DISPLAY_TABLE]: "DisplayTableCell",
  [CellType.METRIC]: "MetricCell",
  [CellType.TEXT]: "TextCell",
  [CellType.MAP]: "MapCell",
  [CellType.WRITEBACK]: "WritebackCell",
  [CellType.DBT_METRIC]: "DbtMetricCell",
  [CellType.PIVOT]: "PivotCell",
  [CellType.FILTER]: "FilterCell",
  [CellType.COMPONENT_IMPORT]: "ComponentImportCell",
  [CellType.CHART]: "ChartCell",
  [CellType.BLOCK]: "BlockCell",
  [CellType.EXPLORE]: "ExploreCell",
  // Unused now, so no value supplied
  [CellType.CELL_GROUP]: undefined,
};

export const CellTypeNameToCellType: Record<
  CellContentsMP["__typename"],
  CellType
> = {
  ["Parameter"]: CellType.INPUT,
  ["MarkdownCell"]: CellType.MARKDOWN,
  ["CodeCell"]: CellType.CODE,
  ["SqlCell"]: CellType.SQL,
  ["VegaChartCell"]: CellType.VEGA_CHART,
  ["DisplayTableCell"]: CellType.DISPLAY_TABLE,
  ["MetricCell"]: CellType.METRIC,
  ["TextCell"]: CellType.TEXT,
  ["MapCell"]: CellType.MAP,
  ["WritebackCell"]: CellType.WRITEBACK,
  ["DbtMetricCell"]: CellType.DBT_METRIC,
  ["PivotCell"]: CellType.PIVOT,
  ["FilterCell"]: CellType.FILTER,
  ["ComponentImportCell"]: CellType.COMPONENT_IMPORT,
  ["ChartCell"]: CellType.CHART,
  ["BlockCell"]: CellType.BLOCK,
  ["ExploreCell"]: CellType.EXPLORE,
  // Unused now, so no value supplied
  // ["CellGroupCell"]: CellType.CELL_GROUP,
};
